import axios from "axios";
import { AuthService } from "./AuthService";
import language from '@/assets/json/language.json';
import tagList from '@/assets/json/tagList.json';
import banner from '@/assets/json/banner.json';
import banner_Mobile from "@/assets/json/banner_Mobile.json";
import themeList from '@/assets/json/themeList.json';
import topDestinations from '@/assets/json/topDestinations.json';
import videoList from '@/assets/json/video.json';
import popularList from '@/assets/json/popularDestination.json';
import map from '@/assets/json/map.json';
import news from '@/assets/json/news.json';
import zagyosohList from '@/assets/json/Project-Zagyosoh.json';
import zagyosohList_Mobile from "@/assets/json/Project-Zagyosoh-Mobile.json";
import ikenotairaList from '@/assets/json/Project-Ikenotaira.json';
import ikenotairaList_Mobile from "@/assets/json/Project-Ikenotaira-Mobile.json";

export class SiteService {
  static createEquipmentInfo() {
    // 步驟 1: 生成 Canvas 指紋
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = "alphabetic";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText("Hello, world!", 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText("Hello, world!", 4, 17);
    const dataURL = canvas.toDataURL();
  
    // 步驟 2: 將指紋轉換為哈希值
    const hash = this.simpleHash(dataURL);
  
    // 步驟 3: 獲取當前的時間戳
    const timestamp = Date.now();
  
    // 步驟 4: 生成一個隨機字符串
    const randomString = this.generateRandomString(16);
  
    // 步驟 5: 將哈希值、時間戳和隨機字符串結合
    const combinedHash = this.simpleHash(hash.toString() + timestamp.toString() + randomString);
  
    // 步驟 6: 將結合後的哈希值轉換為32碼的短碼
    const shortCode = this.hashToShortCode(combinedHash, 30);
  
    return shortCode;
  }
  
  // 生成隨機字符串
  static generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // 將哈希值轉換為短碼
  static hashToShortCode(hash, codeLength) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let shortCode = '';
  
    // 使用位操作來確保hash的每一位都被充分利用
    for (let i = 0; i < codeLength; i++) {
      // 使用位操作來獲取hash的不同部分
      const index = (hash >> (i * 5) & 29) % characters.length;
      shortCode += characters.charAt(index);
    }
  
    return shortCode;
  }
  
  // 將指紋轉換為哈希值
  static simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & 0xFFFFFFFF; // 確保hash始終是32位整數
    }
    return Math.abs(hash);
  }
  
  

  // TODO: get supported display languages from API
  static getSupportedLanguages() {
    // let accessToken = await AuthService.getAccessToken();
    // let dataURL =
    //   process.env.VUE_APP_API_URL + `/api/V1/P02/A00/P02A00F001?Top=10&Skip=1`;
    // let response = await axios({
    //   method: "GET",
    //   url: dataURL,
    //   responseType: "json",
    //   headers: {
    //     Authorization: accessToken,
    //   },
    // })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    //   return response.data.Data.P01A00F001List;
      return language.Data.P01A00F001List;
  }

  // get user display languages
  static getUserDisplayLanguage() {
    // const langObject = localStorage.getItem("displayLang");
    // const langObject = sessionStorage.getItem("displayLang");

    // 20240913, 吃User瀏覽器預設語言, 若非以下4語系, 就轉en-US
    let browserLanguage = navigator.language;
    if(browserLanguage === "ja") {browserLanguage = "ja-JP"} // Chrome的日文預設是 "ja"
    const supportedLanguage = ['en-US', 'zh-TW', 'zh-CN', 'ja-JP'];
    const displayLang = supportedLanguage.includes(browserLanguage) ? browserLanguage : sessionStorage.getItem("displayLang");
    if (displayLang) {
      return displayLang;
    } else {
      //return "ja-JP";
      return "en-US"; // 不是4語系, 就轉英文
    }
  }

  // update user display languages
  static updateUserDisplayLanguage(lang) {
    if (lang == undefined) {
      localStorage.setItem("displayLang", "ja-JP");
    } else {
      localStorage.setItem("displayLang", lang);
    }
  }
  // TODO: get tag list from API
  static getTagList() {
    return tagList.Data.P01A00F002List;
  }
  // TODO: get banner list from API
  static getBannerList(langCode) {
    if (langCode == "zh-TW") {
      return banner.Data.bannerList_zhTW;
    } else if ( langCode == "zh-CN") {
    return banner.Data.bannerList_zhCN;
    } else if ( langCode == "en-US") {
    return banner.Data.bannerList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return banner.Data.bannerList_jaJP;
    }
  }

  // 首頁Banner - 手機板
  static getBannerList_Mobile(langCode) {
    if (langCode == "zh-TW") {
      return banner_Mobile.Data.bannerList_zhTW;
    } else if ( langCode == "zh-CN") {
    return banner_Mobile.Data.bannerList_zhCN;
    } else if ( langCode == "en-US") {
    return banner_Mobile.Data.bannerList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return banner_Mobile.Data.bannerList_jaJP;
    }
  }

  // TODO: get theme list from API
  static getThemeList(langCode) {
    if (langCode == "zh-TW") {
      return themeList.Data.themeList_zhTW;
    } else if ( langCode == "zh-CN") {
    return themeList.Data.themeList_zhCN;
    } else if ( langCode == "en-US") {
    return themeList.Data.themeList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return themeList.Data.themeList_jaJP;
    }
  }
  // TODO: get top destinations from API
  static getTopDestinations(langCode) {
    if (langCode == "zh-TW") {
      return topDestinations.Data.topDesList_zhTW;
    } else if ( langCode == "zh-CN") {
    return topDestinations.Data.topDesList_zhCN;
    } else if ( langCode == "en-US") {
    return topDestinations.Data.topDesList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return topDestinations.Data.topDesList_jaJP;
    }
  }

  // TODO: get video list from API
  static getVideo() {
    return videoList.Data.list;
  }

  // TODO: get popular destinations from API
  static getPopularDestination(langCode) {
    if (langCode == "zh-TW") {
      return popularList.Data.PopularDestination_zhTW;
    } else if ( langCode == "zh-CN") {
      return popularList.Data.PopularDestination_zhCN;
    } else if ( langCode == "en-US") {
      return popularList.Data.PopularDestination_enUs;
    }
    else if ( langCode == "ja-JP") {
      return popularList.Data.PopularDestination_jaJP;
    }
  }

  // TODO: get map from API
  static getMap(langCode) {
    if (langCode == "zh-TW") {
      return map.Data.mapList_zhTW;
    } else if ( langCode == "zh-CN") {
      return map.Data.mapList_zhCN;
    } else if ( langCode == "en-US") {
      return map.Data.mapList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return map.Data.mapList_jaJP;
    }
  }
  
  // TODO: get news from API
  static getNews() {
    return news.Data.news;
  }
  // // confirm user agree cookie policy
  // static isUserAgreeCookiePolicy() {
  //   const agreeObject = localStorage.getItem("agreeCookie");
  //   console.log(agreeObject);
  //   if (agreeObject) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // // set user agree cookie policy
  // static setCookiePolicyAgree() {
  //   localStorage.setItem("agreeCookie", true);
  // }
  
  // TODO: get policy content from API
  static async getPolicyContent(lang) {
    console.log(lang);
    let accessToken = await AuthService.getAccessToken();
    let dataURL =
      process.env.VUE_APP_API_URL + `/api/V1/P02/A05/A01/P02A05A01F007`;
    let response = await axios({
      method: "GET",
      url: dataURL,
      responseType: "json",
      headers: {
        Authorization: accessToken,
      },
    })
      .catch(function (error) {
        console.log(error);
      });
    console.log(response.data.Data.PrivacyList);
    let privacyContents = response.data.Data.PrivacyList.filter(function (
      value
    ) {
      console.log(value.Language);
      return value.Language == lang;
    });

    console.log(privacyContents);

    if (privacyContents.length > 0) {
      return privacyContents[0].Content;
    } else {
      return null;
    }
  }
  

  // 坐魚莊 四國語言 JSON圖檔URL
  static getZagyosohList(langCode) {
    if (langCode == "zh-TW") {
      return zagyosohList.Data.themeList_zhTW;
    } else if ( langCode == "zh-CN") {
    return zagyosohList.Data.themeList_zhCN;
    } else if ( langCode == "en-US") {
    return zagyosohList.Data.themeList_enUS;
    }
    else if ( langCode == "ja-JP") {
    return zagyosohList.Data.themeList_jaJP;
    }
  }

    // 坐魚莊 四國語言 JSON圖檔URL - Mobile
    static getZagyosohList_Mobile(langCode) {
      if (langCode == "zh-TW") {
        return zagyosohList_Mobile.Data.themeList_zhTW;
      } else if ( langCode == "zh-CN") {
      return zagyosohList_Mobile.Data.themeList_zhCN;
      } else if ( langCode == "en-US") {
      return zagyosohList_Mobile.Data.themeList_enUS;
      }
      else if ( langCode == "ja-JP") {
      return zagyosohList_Mobile.Data.themeList_jaJP;
      }
    }
  

    // 池之平 四國語言 JSON圖檔URL
    static getIkenotairaList(langCode) {
      if (langCode == "zh-TW") {
        return ikenotairaList.Data.themeList_zhTW;
      } else if ( langCode == "zh-CN") {
      return ikenotairaList.Data.themeList_zhCN;
      } else if ( langCode == "en-US") {
      return ikenotairaList.Data.themeList_enUS;
      }
      else if ( langCode == "ja-JP") {
      return ikenotairaList.Data.themeList_jaJP;
      }
    }

    // 池之平 四國語言 JSON圖檔URL
    static getIkenotairaList_Mobile(langCode) {
      if (langCode == "zh-TW") {
        return ikenotairaList_Mobile.Data.themeList_zhTW;
      } else if ( langCode == "zh-CN") {
      return ikenotairaList_Mobile.Data.themeList_zhCN;
      } else if ( langCode == "en-US") {
      return ikenotairaList_Mobile.Data.themeList_enUS;
      }
      else if ( langCode == "ja-JP") {
      return ikenotairaList_Mobile.Data.themeList_jaJP;
      }
    }

}



