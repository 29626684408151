import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'

import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// Vue Inline SVG
import InlineSvg from 'vue-inline-svg';

// Fort Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//I18N
import enUS from './translations/en-US.json';
import zhTW from './translations/zh-TW.json';
import zhCN from './translations/zh-CN.json';
import jaJP from './translations/ja-JP.json';
import { createI18n } from 'vue-i18n'

//Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()

// SweetAlert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)

// 偵測 User 瀏覽器語言
let browserLanguage = navigator.language;
if(browserLanguage === "ja") {browserLanguage = "ja-JP"}
const supportedLanguage = ['en-US', 'zh-TW', 'zh-CN', 'ja-JP']; // 官網只提供4種語言包
// 將匹配到的瀏覽器語言, 映射給displayLang, 用三元去判斷
const displayLang = supportedLanguage.includes(browserLanguage) ? browserLanguage : sessionStorage.getItem("displayLang");
console.log(displayLang, "柳覽器語言");

const i18n = createI18n({
  legacy: false,
  locale: displayLang,
  messages: {
    "en-US": enUS,
    "zh-TW": zhTW,
    "zh-CN": zhCN,
    "ja-JP": jaJP,
  },
  fallbackLocale: 'en-US',
});


// Vue Inline SVG
app.component('inline-svg', InlineSvg);

// 1. Facebook Init初始化
// window.fbAsyncInit = function() {
//   FB.init({
//     appId      : '1155406881829935',
//     xfbml      : true,
//     version    : 'v19.0'
//   });
//   FB.AppEvents.logPageView();
// };

// 2. Facebook SDK
// (function(d, s, id){
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) {return;}
//   js = d.createElement(s); js.id = id;
//   js.src = "https://connect.facebook.net/en_US/sdk.js";
//   fjs.parentNode.insertBefore(js, fjs);
// }(document, 'script', 'facebook-jssdk'));

// 4. ======== 千分號方法 ========
import { currency, date } from '@/methods/filters.js'
app.config.globalProperties.$filters = { currency, date }

// 5. Persist
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
pinia.use(piniaPluginPersistedstate);

// 6. Vee-Validate
import {Field, Form, ErrorMessage, defineRule} from 'vee-validate';
// 引入 VeeValidate 的驗證規則, typeof這行, 是Bug解決之判斷
import * as AllRules from '@vee-validate/rules';
Object.keys(AllRules).forEach((rule) => {
  if(typeof AllRules[rule] === 'function'){
    defineRule(rule, AllRules[rule]);
  }
});

app.component('VField', Field);
app.component('VForm', Form);
app.component('ErrorMessage', ErrorMessage);

app.use(VueAxios, axios)
app.use(i18n)
app.use(pinia)

app.use(VueSweetalert2)
app.use(router)
app.mount('#app')


